//import router from '@/router'
import { Vars } from '@/inc/Variables';
//import { Utils } from '@/inc/Utils';

async function __get( url, params = {}, headers = {}, config = {} ){

   try {
      url = new URL(url);

      if( Object.keys(params).length ){
         Object.keys(params).forEach( (key) => {
            url.searchParams.append( key, params[key] );
         })
      }

      headers = { 
         'Content-Type': 'application/json',
         // 'Content-Type': 'application/x-www-form-urlencoded',
         ...headers
      };

      if( params.token ){
         headers['Authorization'] = "Barear " + params.token;
      }

      config = {
         method: 'GET', // *GET, POST, PUT, DELETE, etc.
         mode: 'cors', // no-cors, *cors, same-origin
         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'same-origin', // include, *same-origin, omit
         headers: headers,
         redirect: 'follow', // manual, *follow, error
         referrerPolicy: 'origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
         ...config
      }
      
      var response = await fetch(url.href, config);

      return response.json();
      
   } catch (error) {
      console.log(error)
   }

}

async function __post( url, params = {}, headers = {}, config = {} ){

   try {

      headers = { 
         'Content-Type': 'application/json',
         // 'Content-Type': 'application/x-www-form-urlencoded',
         ...headers
      };

      if( params.token ){
         headers['Authorization'] = "Barear " + params.token;
      }

      config = {
         method: 'POST', // *GET, POST, PUT, DELETE, etc.
         mode: 'cors', // no-cors, *cors, same-origin
         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'same-origin', // include, *same-origin, omit
         headers: headers,
         redirect: 'follow', // manual, *follow, error
         referrerPolicy: 'origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
         body: JSON.stringify( params ), // body data type must match "Content-Type" header
         ...config
      };
      
      var response = await fetch(url, config);

      return response.json();
      
   } catch (error) {
      console.log(error)
   }

}

export async function getCountries({commit}) {
   return await __get( 
      Vars.home_url + Vars.endpoints.get_countries,
      {},
      {
         'Authorization' : 'Barear ' + window.$cookies.get("user_token")
      }
   )
   .then(res => {
      commit("setCountries", res)
      return res;
   });
}