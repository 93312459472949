export function setUserData(state, val) {
   state.userData = val;
   localStorage.setItem('userData', JSON.stringify(state.userData));
}

export function setLoginStatus(state, val) {
   state.is_logged = val;
   localStorage.setItem('is_logged', val);
}

export function setTokenValidationTimeout(state, val) {
   state.next_validation = val;
   localStorage.setItem('next_validation', val);
}

export function resetState(state){
   Object.keys(state).forEach((key)=>{
      
      if( Array.isArray(state[key]) ){
         state[key] = [];
      }else if( typeof state[key] === 'object' ){
         state[key] = {};
      }else{
         state[key] = '';
      }

   });
}