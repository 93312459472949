import Vue from 'vue'
import { store } from '../store'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/info',
    name: 'InfoStruttura',
    component: () => import('../views/InfoStruttura.vue')
  },
  {
    path: '/bar',
    name: 'Bar',
    component: () => import('../views/Bar.vue')
  },
  {
    path: '/ristorante',
    name: 'Ristorante',
    component: () => import('../views/Ristorante.vue')
  },
  {
    path: '/territorio',
    name: 'Territorio',
    component: () => import('../views/Territorio.vue')
  },
  {
    path: '/servizi',
    name: 'Servizi',
    component: () => import('../views/Servizi.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/Account.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/account/:screen',
    name: 'Account info',
    component: () => import('../views/AccountDetails.vue'),
    meta: {
      requiresAuth: true,
      hasBackButton: true,
      backRedirect: '/account'
    }
  },
  {
    path: '/shop',
    name: 'Shop',
    component: () => import('../views/Shop.vue'),
    meta: {
      backRedirect: '/'
    }
  },
  {
    path: '/product/:id',
    name: 'Prodotto',
    component: () => import('../views/ProductDetails.vue'),
    meta: {
      hasBackButton: true
    }
  },
  {
    path: '/order/:id',
    name: 'Ordine',
    component: () => import('../views/OrderDetails.vue'),
    meta: {
      hasBackButton: true
    }
  },
  {
    path: '/cart',
    name: 'Carrello',
    component: () => import('../views/Cart.vue'),
    //beforeEnter: ifAuthenticated,
    meta: {
      requiresAuth: true,
      hasBackButton: true
    }
  },
  {
    path: '/pagamento-completato',
    name: 'Completato',
    component: () => import('../views/PagamentoCompletato.vue'),
    //beforeEnter: ifAuthenticated,
    meta: {
      requiresAuth: true,
      backRedirect: '/'
    }
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {

  const token = Vue.$cookies.get('user_token');
  const requiresAuth = to.matched[0].meta && to.matched[0].meta.requiresAuth ? to.matched[0].meta.requiresAuth : undefined;
  //console.log(requiresAuth, to, token)

  if (requiresAuth == undefined) {
    next();
  }

  if (requiresAuth == false) {
    if (token) {
      next({
        path: '/'
      });
    } else {
      next();
    }
  }

  if (requiresAuth == true) {
    if (token) {
      // this route requires auth, check if user is logged in
      // we need to check if the token is still valid
      store.dispatch('account/validate_token')

    } else {

      store.dispatch('account/logout')

      next({
        path: '/login'
      })

    }
  }

  next();

})

export default router
