<template>
  <div id="app">

    <Header />
    
    <transition name="slide">
      <router-view></router-view>
    </transition>
    
    <BottomNavigation v-bind:itemList="bottom_navigation_list" />

    <Modal
      v-show="modal.isModalVisible"
      @close="closeModal"
    />
  
  </div>
</template>

<style lang="scss">
@import "@/assets/fonts/fontawesome-pro-6/all.min.scss";
@import "@/assets/css/style.scss";
</style>

<script>
import Header from '@/layout/Header.vue';
import BottomNavigation from '@/components/BottomNavigation.vue';
import { Utils } from '@/inc/Utils';
import { Vars } from '@/inc/Variables';
import Modal from '@/components/Modal.vue';

export default {
  name: 'App',
  mixins: [Utils],
  components: {
    Header, BottomNavigation, Modal
  },
  data(){
    return {
      bottom_navigation_list: Vars.bottom_navigation.main
    }
  }
}
</script>
