import router from '@/router'
import { Vars } from '@/inc/Variables';
//import { Utils } from '@/inc/Utils';

async function __get( url, params = {}, headers = {}, config = {} ){

   try {
      url = new URL(url);

      if( Object.keys(params).length ){
         Object.keys(params).forEach( (key) => {
            url.searchParams.append( key, params[key] );
         })
      }

      headers = { 
         'Content-Type': 'application/json',
         // 'Content-Type': 'application/x-www-form-urlencoded',
         ...headers
      };

      if( params.token ){
         headers['Authorization'] = "Barear " + params.token;
      }

      config = {
         method: 'GET', // *GET, POST, PUT, DELETE, etc.
         mode: 'cors', // no-cors, *cors, same-origin
         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'same-origin', // include, *same-origin, omit
         headers: headers,
         redirect: 'follow', // manual, *follow, error
         referrerPolicy: 'origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
         ...config
      }
      
      var response = await fetch(url.href, config);

      return response.json();
      
   } catch (error) {
      console.log(error)
   }

}

async function __post( url, params = {}, headers = {}, config = {} ){

   try {

      headers = { 
         'Content-Type': 'application/json',
         // 'Content-Type': 'application/x-www-form-urlencoded',
         ...headers
      };

      if( params.token ){
         headers['Authorization'] = "Barear " + params.token;
      }

      config = {
         method: 'POST', // *GET, POST, PUT, DELETE, etc.
         mode: 'cors', // no-cors, *cors, same-origin
         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'same-origin', // include, *same-origin, omit
         headers: headers,
         redirect: 'follow', // manual, *follow, error
         referrerPolicy: 'origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
         body: JSON.stringify( params ), // body data type must match "Content-Type" header
         ...config
      };
      
      var response = await fetch(url, config);

      return response.json();
      
   } catch (error) {
      console.log(error)
   }

}

export async function validate_token({ commit, state, dispatch }) {
   
   if( Math.floor(Date.now() / 1000) <= parseInt(state.next_validation) ){
      return;
   }
   
   return await __post( 
      Vars.home_url + Vars.endpoints.auth.token_validate, 
      {
         refresh_token:  window.$cookies.get("user_token_refresh")
      },
      {
         'Authorization' : 'Barear ' + window.$cookies.get("user_token")
      }
   ).then(res => {

      var next_validation = Math.floor(Date.now() / 1000) + 600; // 10 minuti
      commit("setTokenValidationTimeout", next_validation);
      
      if( ! res ){
         dispatch('logout');
         return;
      }

      window.$cookies.set("user_token", res.token, new Date(res.token_expire * 1000));
      dispatch('get_user');

   }).catch( (err) => {

      dispatch('logout');
      return err;
   });

}

export async function login({ commit }, payload) {
   return await __post( 
      Vars.home_url + Vars.endpoints.auth.token, 
      payload
   )
   .then(res => {
      
      if( res.token ){
         window.$cookies.set("user_token", res.token, new Date(res.token_expire * 1000));
         window.$cookies.set("user_token_refresh", res.refresh_token, new Date(res.refresh_token_expire * 1000));
         commit("setLoginStatus", true);
         router.push('/account')
      }

      return res;
   });

}

export function logout() {
   //account
   //commit("resetState");
   
   //reset storage
   var exclude = ['loglevel:webpack-dev-server'];
   Object.keys(localStorage).forEach((key)=>{
       if( ! exclude.includes(key) ){
           localStorage.removeItem(key);
       }
   });
   window.$cookies.remove("user_token");
   window.$cookies.remove("user_token_refresh");
   //redirect
   router.push('/login');
}

export async function get_user( {commit} ) {
   return await __get( 
      Vars.home_url + Vars.endpoints.get_user,
      {},
      {
         'Authorization' : 'Barear ' + window.$cookies.get("user_token")
      }
   )
   .then(res => {

      if( res ){
         commit("setUserData", res)
      }
      
   });

}

export async function save_user_data( {dispatch}, payload ){

   return await __post( 
      Vars.home_url + Vars.endpoints.update_user_data, 
      payload,
      {
         'Authorization' : 'Barear ' + window.$cookies.get("user_token")
      }
   ).then(res => {
      dispatch('get_user');
      return res;

   }).catch( (err) => {
      return err;
   });

}

export async function save_customer_data( {dispatch}, payload ){
   
   return await __post( 
      Vars.home_url + Vars.endpoints.update_user_data, 
      payload,
      {
         'Authorization' : 'Barear ' + window.$cookies.get("user_token")
      }
   ).then(res => {
      dispatch('get_user');
      return res;

   }).catch( (err) => {
      return err;
   });

}