<template>
  <div id="header" class="container py-3">
    <div id="logo">
      <router-link to="/">
        <img src="@/assets/images/vacanze-a-braccetto-logo.png" />
        <img src="@/assets/images/varoamare-logo.png" />
      </router-link>
    </div>

    <div>
      <div class="d-flex actions">
        <router-link to="/account" id="account-button" v-if="is_logged">
          <i class="fa-regular fa-user-check"></i>
        </router-link>

        <router-link to="/login" id="account-button" v-else>
          <i class="fa-regular fa-user"></i>
        </router-link>

        <!-- <router-link to="/cart" class="ms-2">
               <i class="fa fa-shopping-cart"></i>
               <span class="badge badge-danger badge-pill">{{ cart.length }}</span>
            </router-link> -->
      </div>
    </div>
  </div>
</template>

<script scoped>
//import { Vars } from '@/inc/Variables';
import { mapGetters } from "vuex";

export default {
  name: "Header",

  computed: {
    ...mapGetters("account", ["is_logged"]),
  },

  data() {
    return {
      cart: [],
    };
  },
};
</script>

<style scoped>
#header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0 0.1rem 1rem rgb(0 0 0 / 8%);
}
#logo {
  display: flex;
  align-items: center;
}
#logo img {
  height: 44px;
}
#logo img:not(:first-child) {
  margin-left: 15px;
}
.actions a {
  color: inherit;
  width: 30px;
  line-height: 30px;
  text-align: center;
}
#side-panel {
  color: inherit;
  width: 30px;
  line-height: 30px;
  text-align: center;
}
</style>