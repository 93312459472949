const base = 'https://www.invacanzabraccetto.com';

export const Vars = {
   home_url: base,
   logo: base + '/wp-content/themes/dftheme/images/logo.svg',
   payments: {
      currency: 'EUR',
      paypal: {
         client_id: 'AfwEZHGkI8aGdIbg4fpp97Tj2-yEs6XD03EaUR7dUvW3eiSkKJ7cskodbsrpKBn946T_jpItk36gYopI'
      }
   },
   onesignal: {
      appid: "38eca630-476d-4413-9c49-1bdee77c94fb"
   },
   endpoints : {
      //wp
      post_categories : '/wp-json/wp/v2/categories',
      posts : '/wp-json/wp/v2/posts',
      notifiche : '/wp-json/wp/v2/notifica',
      users: '/wp-json/wp/v2/users',
      register_user: '/wp-json/wp/v2/users/register',
      change_password: '/wp-json/wp/v2/users/change-password',
      reset_password: '/wp-json/df-app/v1/resetpasswordlink',
      //wc
      customer: '/wp-json/wc/v3/customers',
      products: '/wp-json/wc/v3/products',
      product_categories: '/wp-json/wc/v3/products/categories',
      orders: '/wp-json/wc/v3/orders',
      
      // app controller
      // authentication
      auth: {
         token: '/wp-json/app_controller/v1/token',
         token_validate: '/wp-json/app_controller/v1/token-validate',
         token_refresh: '/wp-json/app_controller/v1/token_refresh',
      },
      // contents
      app_contents: '/wp-json/app_controller/v1/get-app-contents',
      // user
      get_user: '/wp-json/app_controller/v1/get-user',
      update_user_data: '/wp-json/app_controller/v1/update-user-data',
      //wc
      get_countries: '/wp-json/app_controller/v1/get-countries',
   },
   product_categories_ordering: ['corsi','lezioni','bambini'],
   bottom_navigation : {
      main: [
         {
            path: '/info',
            name: 'Info',
            icon: 'fa-regular fa-message-exclamation',
         },
         {
            path: '/notices',
            name: 'Notices',
            icon: 'fa-regular fa-bell'
         },
         {
            path: '/',
            name: 'Home',
            icon: 'fa-regular fa-home'
         },
         {
            path: '/shop',
            name: 'Shop',
            icon: 'fa-regular fa-shopping-bag'
         },
         {
            icon: 'fa-regular fa-phone-flip',
            href: 'tel:+393311548269'
         }
      ]
   }
}