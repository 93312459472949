<template>
   <transition name="modal-fade">
      <div class="modale-wrap">
         <div class="modale shadow-lg d-flex flex-column">

            <div class="text-end">
               <div class="close d-inline-flex align-items-center justify-content-center m-2" 
                  @click="close"
               >
                  <i class="fa-light fa-times"></i>
               </div>
            </div>
               
            <div class="inner px-3 h-100">
               <h2 v-html="modal.header"></h2>
               <div class="content" v-html="modal.body"></div>
            </div>

            <div class="text-center p-3"
               @click="close">
               <span class="text-decoration-uppercase">Chiudi</span>
            </div>
         </div>
      </div>
   </transition>
</template>

<script>
import { Utils } from '@/inc/Utils';

export default {
   name: 'Modal',
   mixins: [Utils],
   methods: {
      close() {
         this.$emit('close');
      },
   }
};
</script>

<style>
.modale-wrap {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 10;
   padding: 1rem;
}
.modale{
   height: 100%;
   background: #fff;
}
.modale .close{
   width: 32px;
   height: 32px;
   font-size: 27px;
   cursor: pointer;
}
.modale .inner {
    overflow: hidden;
    overflow-y: scroll;
}
.modal-fade-enter,
.modal-fade-leave-to {
   opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
   transition: opacity .25s ease;
}
</style>
