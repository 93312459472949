export default function () {
   return {
      products: [],
      product: {},
      products_request_params: {
         page: 1,
         per_page: 100,
         status: 'publish',
         stock_status: 'instock',
         order_by: 'date',
         order: 'asc',
      },
      product_categories: [],
      product_category: {},
      cart: JSON.parse(localStorage.getItem('cart')) || [],
      countries: JSON.parse(localStorage.getItem('countries')) || {},
   }
 }