export function user (state) {
   return state.userData
}

export function is_logged (state) {
   return !! state.is_logged
}

export function next_validation (state) {
   return state.next_validation
}

export function deviceInfo( state ){
   return state.deviceInfo;
}