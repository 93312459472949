<template id="bottom-navigation">
   <div id="bottom-nav" class="shadow-lg">

      <div v-for="(item,index) in itemList" :key="index" >
         <router-link v-if="item.path" :to="item.path" class="icon-wrap">
            <img v-if="item.image" :src="item.icon" />
            <i v-else :class="item.icon"></i>
         </router-link>
         <a v-if="item.href" :href="item.href" class="icon-wrap">
            <img v-if="item.image" :src="item.icon" />
            <i v-else :class="item.icon"></i>
         </a>
      </div>
      
   </div>
</template>

<script scoped>

export default {
   name: 'BottomNavigation',
   props : [ 'itemList' ],
   /* data(){
      return {
         images: []
      }
   },
   mounted(){
      this.itemList.forEach( (item) => {
         this.images[item.path] = item.icon;
      })
   } */
};
</script>

<style scoped>
#bottom-nav {
   display: flex;
   justify-content: space-around;
   position: fixed;
   bottom: 0;
   width: 100%;
}
#bottom-nav a {
   margin: 10px 5px;
   font-size: 1.2rem;
   text-decoration: none;
   position: relative;
   display: inline-block;
}
#bottom-nav a i{
   width: 41px;
   height: 41px;
   display: flex;
   align-items: center;
   justify-content: center;
   -webkit-transition: all .2s;
   -moz-transition: all .2s;
   -o-transition: all .2s;
   -ms-transition: all .2s;
   transition: all .2s;
}
#bottom-nav a.router-link-exact-active i{
   background-color: var(--bs-primary);
   color: white;
   padding: 10px;
   border-radius: 50%;
}
</style>