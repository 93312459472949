import $ from "jquery";
import { Vars } from '@/inc/Variables';

export const Utils = {
   
   data(){
      return {
         contents: (localStorage.getItem('contents')) ? JSON.parse(localStorage.getItem('contents')) : {},
         modal: {
            isModalVisible: false,
            header: '',
            body: '',
         }
      }
   },

   methods: {

      getOS() {
         var userAgent = window.navigator.userAgent,
             platform = window.navigator.platform,
             macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
             windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
             iosPlatforms = ['iPhone', 'iPad', 'iPod'],
             os = null;
       
         if (macosPlatforms.indexOf(platform) !== -1) {
           os = 'Mac OS';
         } else if (iosPlatforms.indexOf(platform) !== -1) {
           os = 'iOS';
         } else if (windowsPlatforms.indexOf(platform) !== -1) {
           os = 'Windows';
         } else if (/Android/.test(userAgent)) {
           os = 'Android';
         } else if (!os && /Linux/.test(platform)) {
           os = 'Linux';
         }
       
         return os;
      },
      __( value ){
         return value;
      },

      capitalize( string, splitFlag = '-', joinFlag = '' ){
         return string.split(splitFlag).map((el) => { return el.charAt(0).toUpperCase().concat(el.slice(1)); }).join(joinFlag);
      },

      makeid( length = 6 ) {
         var result           = '';
         var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
         var charactersLength = characters.length;
         for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
         }
         return result;
      },

      async __get( url, params = {} ){

         try {
            url = new URL(url);

            if( Object.keys(params).length ){
               Object.keys(params).forEach( (key) => {
                  url.searchParams.append( key, params[key] );
               })
            }
            
            var response = await fetch(url.href, {
               method: 'GET', // *GET, POST, PUT, DELETE, etc.
               mode: 'cors', // no-cors, *cors, same-origin
               cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
               credentials: 'omit', // include, *same-origin, omit
               headers: {
                 'Content-Type': 'application/json'
                 // 'Content-Type': 'application/x-www-form-urlencoded',
               },
               redirect: 'follow', // manual, *follow, error
               referrerPolicy: 'no-referrer' // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            });

            return response.json();
            
         } catch (error) {
            console.log(error)
         }

      },

      async __post( url, params = {} ){

         try {
            
            var response = await fetch(url, {
               method: 'GET', // *GET, POST, PUT, DELETE, etc.
               mode: 'cors', // no-cors, *cors, same-origin
               cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
               credentials: 'omit', // include, *same-origin, omit
               headers: {
                 'Content-Type': 'application/json'
                 // 'Content-Type': 'application/x-www-form-urlencoded',
               },
               redirect: 'follow', // manual, *follow, error
               referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
               body: JSON.stringify( params ) // body data type must match "Content-Type" header
            });

            return response.json();
            
         } catch (error) {
            console.log(error)
         }

      },

      /** 
       * modals 
       */

      showModal() {
         if( ! this.modal.header && ! this.modal.body ){
            this.modal.isModalVisible = false;
            return;
         }
         this.modal.isModalVisible = true;
      },

      closeModal() {
         this.modal.isModalVisible = false;
      },

      setModal( header = '', body = '', show = false ){
         this.modal.header = header;
         this.modal.body = body;
         if( show ){
            this.showModal();
         }else{
            this.closeModal();
         }
      },

      destroyModal(){
         this.setModal();
      },

      isElementInView: function (element, fullyInView = false, top = null, bottom = null) {
         if( ! $(element).length ){ return false; }
         var pageTop = $(window).scrollTop();
         var pageBottom = pageTop + $(window).height();
         var elementTop = (top != null) ? top : $(element).offset().top;
         var elementBottom = elementTop + $(element).height();
         elementBottom += (bottom != null) ? bottom : 0;
   
         if (fullyInView === true) {
            return ((pageTop < elementTop) && (pageBottom > elementBottom));
         } else {
            return ((elementTop <= pageBottom) && (elementBottom >= pageTop));
         }
      },

      validPassword: function(password){
         var errors = [];
         var find_special = new RegExp("[^A-Za-z0-9]");
         var find_numero = new RegExp("[0-9]");
         var find_maiuscola = new RegExp("[A-Z]");
         var find_minuscola = new RegExp("[a-z]");

         var results = [
            find_special.test(password),
            find_numero.test(password),
            find_maiuscola.test(password),
            find_minuscola.test(password)
         ];

         var score = results.filter(function(el){
            return (el) ? el : false;
         })
        

         if( score.length >= 4 ){

            if( password.length < 8 ){
               errors.push({text:'Password troppo corta'});
            }

         }else{
            
            errors.push({text:'La password deve contenere almeno 8 caratteri tra maiuscole, minuscole, simboli e numeri<br>es: <em>P@ssword1</em>'});
                        
         }

         return errors;
      },

      switchPasswordView: function(el){
         var wrap = $(el).closest('.pass-wrap');
         var input = wrap.find('input');
         if( input.attr('type') == 'password' ){
            input.attr('type', 'text');
            wrap.find('.switcher i').attr('class','fa-regular fa-eye-slash');
         }else{
            input.attr('type','password');
            wrap.find('.switcher i').attr('class','fa-regular fa-eye');
         }
      },

      /**
       * Get contents fro API
       * @param {string} index 
       * @returns html content
       */

      get_app_content( index ){
         var vm = this;
         return this.__get( Vars.home_url + Vars.endpoints.app_contents, { content: index } )
         .then(res => {
            vm.contents[index.replaceAll('-','_')] = res;
            localStorage.setItem('contents', JSON.stringify(this.contents));
            return res;
         });
      }
   },

   mounted(){
      var vm = this;

      //modals
      $(document).on('click','[data-modal]', function(){
         var header = $(this).data('title');
         var body = $($(this).data('content')).html();
         vm.setModal( header, body, true );
      })
      
      //accordion
      $(document).on('click','.accordion-title', function(){
         var accordion = $(this).closest('.accordion');
         accordion.toggleClass('active');
         setTimeout( () => {
            var h = accordion.find('.accordion-content').height();
            var pos = accordion.offset();
            window.scrollTo(0, pos.top + h);
         }, 300 )
      });
   }
}