import { Vars } from '@/inc/Variables';

export function setProductCategories(state, val) {
   var ordered = Vars.product_categories_ordering;
   const sorter = (a, b) => {
      return ordered.indexOf(a.slug) - ordered.indexOf(b.slug);
   };
   state.product_categories = val.sort(sorter);
}
export function setProductCategory(state, val) {
   state.product_category = val
}
export function setProducts(state, val) {
   state.products = val
}
export function setProduct(state, val) {
   state.product = val
}
export function setProductsRequestParams(state, val) {
   state.products_request_params = val
}
export function setLoad(state, val) {
   state.uploadingData = val
}
export function setCart(state, val) {
   val = (val == null) ? [] : val;
   localStorage.setItem('cart', JSON.stringify(val));
   state.cart = val
}

export function resetState(state){
   state.cart = [];
   state.userData = {};
   state.orders = [];
}

export function setCountries(state, val) {
   val = (val == null) ? [] : val;
   localStorage.setItem('countries', JSON.stringify(val));
   state.countries = val
}